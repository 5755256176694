import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav } from "react-bootstrap"
import Logo from "../images/Schriftzug-Weiß.svg"

import "./css/header.scss"

const Header = ({ siteTitle }) => (
  <Navbar
    className="header"
    collapseOnSelect
    sticky="top"
    expand="lg"
    bg="dark"
    variant="dark"
  >
    <Link to="/#top">
      <Logo width="195" height="30" className="d-inline-block align-top" />
    </Link>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto"></Nav>
      <Nav>
        <Link to="/#top" className="nav-link active">
          Startseite
        </Link>
        <Link to="/#about" className="nav-link active">
          Über uns
        </Link>
        <Link to="/#events" className="nav-link active">
          Auftritte
        </Link>
        <Link to="/#footer" className="nav-link active">
          Kontakt
        </Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
