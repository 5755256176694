import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import "bootstrap/dist/css/bootstrap.min.css"
import "./css/footer.scss"

import Header from "./header"
// import CookieConsent from "react-cookie-consent"

import MailIcon from "../images/mailIcon.svg"
import InstagramIcon from "../images/instagramIcon.svg"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>

        {/* <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="CookieConsent"
          containerClasses="container-fluid m-0"
          contentClasses="text mx-auto col-10 col-lg-11"
          disableButtonStyles={true}
          buttonClasses="btn btn-danger col-2 col-lg-1 mx-auto w-100"
          buttonStyle={{
            margin: "auto",
            marginRight: "8px",
            marginLeft: "8px",
          }}
          contentStyle={{ textAlign: "center" }}
          // debug={true}
        >
          Diese Webseite verwendet Cookies zur Verbesserung der Nutzererfahrung.
        </CookieConsent> */}

        <footer className="footer" id="footer">
          <p className="content">
            <span>
              <Link to="/impressum">Impressum</Link>
            </span>
            <span>
              <Link to="/datenschutz">Datenschutz</Link>
            </span>
            <span className="instagram">
              <a
                href="https://www.instagram.com/octatonics/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon className="instagramIcon" />
                Instagram
              </a>
            </span>
            <span>
              <a href="mailto:band@octatonics.de">
                <MailIcon className="mailIcon" />
                band@octatonics.de
              </a>
            </span>
          </p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
